import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const title = '小缘文化 CXC-GROUP.COM'
const keyword = '传统文化,佛龛,莲花灯,蜡烛,电子蜡烛,电子莲花灯,功德箱,电子功德箱,电子佛龛,智能蜡烛,智能莲花灯,智能功德箱,智能佛龛,禅意文化,禅,佛事用品,助手,供应链,商城,智能寺庙,寺庙'
const description = "小缘文化始终坚持，科技赋能寺庙场景的 使命，以智能科技环保安全为企业发展宗旨，不 断创新，追求卓越，致力成为国内寺庙服务的顶 尖品牌，以先进的产品和服务为寺庙领域提供更 好的祈福体验"

const routes = [{
    path: "/",
    name: "Home",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }

  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/",
    name: "MobileHome",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/Home.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/about",
    name: "MobileHome",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/About.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/apply",
    name: "MobileApply",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/Apply.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/submit",
    name: "MobileSubmit",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/Submit.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/development",
    name: "MobileDevelopment",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/development.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },

  {
    path: "/m/niche",
    name: "MobileTestNiche",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/TestNiche.vue"),
    meta: {
      title: '佛龛模拟',
      keywords: keyword,
      description: description
    }
  },

  {
    path: "/m/light",
    name: "MobileTestNiche",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/lightTest.vue"),
    meta: {
      title: '莲花灯模拟器',
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/myQf",
    name: "MobileTestNiche",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/myQf.vue"),
    meta: {
      title: '我的祈福',
      keywords: keyword,
      description: description
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
});

export default router;