import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import echarts from "echarts";
import Vant, {
  Lazyload
} from "vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Lazyload);
Vue.use(Vant)
Vue.use(ElementUI)

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api' //关键代码

if (navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
  localStorage.setItem("isMobile", 1)
} else {
  localStorage.setItem("isMobile", 0)
}

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('isMobile') == 1) {
    if (to.path.indexOf('/m/') < 0) {
      next({
        path: '/m' + to.path
      })
    }
  } else {
    if (to.path.indexOf('/m/') > -1) {
      let path = to.path.substring(2, to.path.length)
      next({
        path: path
      })
    }
  }
  /* 路由发生变化修改页面meta */
  let head = document.getElementsByTagName('head');
  let firstChild = head[0].firstChild
  if (to.meta.content) {
    let meta = document.createElement('meta');
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  if (to.meta.keywords) {
    let meta = document.createElement('meta');
    meta.name = "keywords"
    meta.content = to.meta.keywords;
    head[0].insertBefore(meta, firstChild)
  }
  if (to.meta.description) {
    let meta = document.createElement('meta');
    meta.name = "description"
    meta.content = to.meta.description;
    head[0].insertBefore(meta, firstChild)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");